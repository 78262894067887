import {Box, LinearProgress, makeStyles} from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import React from 'react'

import {HCThemeType} from '../../HCTheme.types'
import {ErrorText} from '../ErrorText'

import {Toolbar, ToolbarProps} from './Toolbar'

interface Error {
  key: string
  label: string
}

export interface FilterBarProps {
  toolbarProps?: ToolbarProps
  progressProps?: any
  paperProps?: any
  isLoading?: boolean
  errors?: Error[]
  showErrorAsInfo?: boolean
  'data-test-id'?: string
  children: React.ReactNode
}

const useStyle = makeStyles((theme: HCThemeType) => ({
  alert: {
    backgroundColor: theme.palette.background.default
  }
}))

export const FilterBar: React.FC<FilterBarProps> = ({
  children,
  toolbarProps,
  progressProps,
  paperProps,
  isLoading = false,
  errors = [],
  showErrorAsInfo = false,
  'data-test-id': dataTestIdProps,
  ...props
}) => {
  const classes = useStyle()
  const dataTestId = dataTestIdProps || 'FilterBar'
  return (
    <>
      <Toolbar data-test-id={dataTestId} {...toolbarProps}>
        {children}
      </Toolbar>
      {isLoading && (
        <LinearProgress data-test-id={`${dataTestId}-loadingIndicator`} {...progressProps} />
      )}

      {errors.map((error, i) =>
        showErrorAsInfo ? (
          <Alert
            key={`error-${i}`}
            className={classes.alert}
            data-test-id="error-text-alert"
            severity="info"
            variant={'filled'}
            icon={false}
          >
            {error.label}
          </Alert>
        ) : (
          <ErrorText data-test-id="error-text" key={i} label={error.label} />
        )
      )}
    </>
  )
}
