import {Box, Button} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'

import Typography from './Typography'

interface ErrorTextType {
  label: string
  onRetry?: () => void
  showRetry?: boolean
}

export const ErrorText: React.FC<ErrorTextType> = ({label, onRetry, showRetry, ...otherProps}) => {
  const {t} = useTranslation()

  return (
    <Box p={4} display="flex" flexDirection="column" alignItems="center" {...otherProps}>
      <Typography variant="body1" customColor="textError">
        {label}
      </Typography>
      {showRetry && (
        <Button variant="text" onClick={onRetry}>
          {t('errorboundary.retryLink')}
        </Button>
      )}
    </Box>
  )
}
