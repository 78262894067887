import {IconButton} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import FilterListIcon from '@mui/icons-material/FilterList'
import {Badge, Box, Button, Checkbox, Menu, MenuItem} from '@mui/material'
import clsx from 'clsx'
import qs from 'query-string'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import Typography from '../../Typography'
import {useStoreFilters} from '../hooks/useStoreFilters'

import {TableFilterItem} from './Item/TableFilterItem'
import {TableFilterTypeProps} from './TableFilter.enum'
import {useStyles} from './TableFilter.styles'
import {TableFilterProps, TableFilterType} from './TableFilter.types'

export const TableFilter = ({
  filterListInputValues,
  isMobile,
  onFilterInputChange,
  onFilterRemove,
  setFilterListInputValues,
  columns,
  extraFilters,
  loading,
  storeFiltersOptions
}: TableFilterProps) => {
  const {classes} = useStyles()
  const {t} = useTranslation()
  const parsedFilters = qs.parse(window.location.search)
  const [predefinedFiltersLoaded, setPredefinedFiltersLoaded] = useState(false)

  const [shouldOpenMobileFilterMenu, setShouldOpenMobileFilterMenu] = useState(false)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const {storedActiveFilters} = useStoreFilters(filterListInputValues, loading, storeFiltersOptions)
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isMobile) {
      setShouldOpenMobileFilterMenu(true)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleMobileMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const parsedFiltersKeys = Object.keys(parsedFilters)

  const predefinedFilters = columns
    .filter((column) => parsedFiltersKeys.includes(column.field) && !column.hideFilterByDefault)
    .map(
      (column) =>
        ({
          filterField: column.field,
          filterName: column.filterName || column.headerName,
          filterType: column.filterType,
          value: parsedFilters[column.field],
          filterDropdownOptions: column.filterDropdownOptions,
          filterDatePickerRangeOptions: column.filterDatePickerRangeOptions && {
            validFrom: {
              filterField: column?.filterDatePickerRangeOptions?.validFromField || '',
              value: parsedFilters?.[column?.filterDatePickerRangeOptions?.validFromField]
            },
            validTo: {
              filterField: column?.filterDatePickerRangeOptions?.validToField || '',
              value: parsedFilters?.[column?.filterDatePickerRangeOptions?.validToField]
            }
          },
          filterShowSearch: column.filterShowSearch,
          filterMultiSelect: column.filterMultiSelect,
          noSelectionDropdownLabel: column.noSelectionDropdownLabel
        }) as TableFilterType
    )

  if (extraFilters) {
    const predefinedExtraFilters = extraFilters
      .filter((column) => parsedFiltersKeys.includes(column.filterField || ''))
      .map(
        (column) =>
          ({
            filterField: column.filterField,
            filterName: column.filterName,
            filterType: column.filterType,
            filterDropdownOptions: column.filterDropdownOptions,
            filterShowSearch: column.filterShowSearch,
            filterMultiSelect: column.filterMultiSelect,
            noSelectionDropdownLabel: column.noSelectionDropdownLabel,
            value: parsedFilters[column.filterField || '']
          }) as TableFilterType
      )
    if (predefinedExtraFilters.length) {
      predefinedFilters.push(...predefinedExtraFilters)
    }
  }

  const handleStoredFilters = (filterField: string) => {
    const matchFilterColumn = columns.find((column) => column.field === filterField)
    const matchExtraFilters = extraFilters?.find(
      (extraFilter) => extraFilter.filterField === filterField
    )

    return matchFilterColumn
      ? ({
          ...matchFilterColumn,
          filterField: matchFilterColumn.field,
          filterName: matchFilterColumn.filterName || matchFilterColumn.headerName,
          value: '',
          filterDatePickerRangeOptions: {
            validTo: {
              filterField: matchFilterColumn.filterDatePickerRangeOptions?.validToField,
              value: ''
            },
            validFrom: {
              filterField: matchFilterColumn.filterDatePickerRangeOptions?.validFromField,
              value: ''
            }
          },
          filterDropdownOptions: matchFilterColumn.filterDropdownOptions
        } as TableFilterType)
      : matchExtraFilters
  }

  const handleResetFilters = () => {
    localStorage.removeItem(
      `activeFilters-${storeFiltersOptions?.tableName}-${storeFiltersOptions?.userId}`
    )
    setFilterListInputValues([])
  }

  const removeFilter = (filter: TableFilterType) => {
    const storedFiltersKey = `activeFilters-${storeFiltersOptions?.tableName}-${storeFiltersOptions?.userId}`

    const filteredStoreFilter =
      storedActiveFilters &&
      JSON.stringify(
        JSON.parse(storedActiveFilters).filter(
          (storedActiveFilter) => storedActiveFilter !== filter.filterField
        )
      )

    filteredStoreFilter && localStorage.setItem(storedFiltersKey, filteredStoreFilter)

    setFilterListInputValues(
      filterListInputValues.filter((value) => value.filterField !== filter?.filterField)
    )
  }

  const addFilter = (filter: TableFilterType) => {
    setFilterListInputValues([
      ...filterListInputValues,
      {
        filterField: filter?.filterField || '',
        filterName: filter?.filterName || '',
        value: '',
        filterType: filter?.filterType,
        filterDropdownOptions: filter?.filterDropdownOptions,
        filterDatePickerRangeOptions: filter.filterDatePickerRangeOptions && {
          validFrom: {
            filterField: filter?.filterDatePickerRangeOptions?.validFrom?.filterField || ''
          },
          validTo: {
            filterField: filter?.filterDatePickerRangeOptions?.validTo?.filterField || ''
          }
        },
        filterShowSearch: filter.filterShowSearch,
        filterMultiSelect: filter.filterMultiSelect,
        noSelectionDropdownLabel: filter.noSelectionDropdownLabel
      }
    ])
  }

  const handleFilter = (filter?: TableFilterType) => {
    const isFilteredSelected = mappedFiltersInputs.includes(filter?.filterField)
    const shouldAddFilter =
      filter?.filterField &&
      filterListInputValues &&
      !filterListInputValues?.find((inputValue) =>
        inputValue.filterType === TableFilterTypeProps.DATEPICKER_RANGE
          ? inputValue.filterDatePickerRangeOptions?.validTo?.filterField ===
              filter?.filterDatePickerRangeOptions?.validTo?.filterField &&
            inputValue.filterDatePickerRangeOptions?.validFrom?.filterField ===
              filter?.filterDatePickerRangeOptions?.validFrom?.filterField
          : inputValue.filterName === filter?.filterField
      )

    shouldAddFilter && (isFilteredSelected ? removeFilter(filter) : addFilter(filter))
  }

  useEffect(() => {
    const formattedStoredActiveFilters =
      storedActiveFilters &&
      ((JSON.parse(storedActiveFilters) as string[])
        .map((activeFilter) => handleStoredFilters(activeFilter))
        .filter((value) => value !== undefined) as TableFilterType[] | '')

    if (
      !loading &&
      !predefinedFiltersLoaded &&
      filterListInputValues.every((value) => predefinedFilters.includes(value))
    ) {
      setFilterListInputValues(
        predefinedFilters.length === 0 && formattedStoredActiveFilters
          ? formattedStoredActiveFilters
          : predefinedFilters
      )
      setPredefinedFiltersLoaded(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const CloseButton = ({onClose, disabled = false}) => {
    return (
      <Box display="flex" justifyContent="flex-end" alignItems="center" height={80}>
        <div>
          <IconButton
            onClick={() => onClose()}
            style={{border: 'none', boxShadow: 'none'}}
            data-test-id="modal-close-button"
            disabled={disabled}
          >
            <Close fontSize="medium" />
          </IconButton>
        </div>
      </Box>
    )
  }

  const tableFilterItemList = filterListInputValues.map((filter, index) => {
    const storedFiltersKey = `activeFilters-${storeFiltersOptions?.tableName}-${storeFiltersOptions?.userId}`
    const filteredStoreFilter =
      storedActiveFilters &&
      JSON.stringify(
        JSON.parse(storedActiveFilters).filter(
          (storedActiveFilter) => storedActiveFilter !== filter.filterField
        )
      )
    return (
      <TableFilterItem
        key={`${filter.filterField}-${index}`}
        data-test-id={`filter-list-item-${filter.filterField}`}
        onInputChange={onFilterInputChange}
        onClose={(filter: TableFilterType) => {
          filteredStoreFilter && localStorage.setItem(storedFiltersKey, filteredStoreFilter)
          onFilterRemove(filter)
        }}
        inputValue={
          filterListInputValues?.find((inputValue) => inputValue.filterField === filter.filterField)
            ?.value || ''
        }
        filter={filter}
        label={t('tableFilter.filter', {
          filterName:
            columns.find((column) => column.field === filter.filterName)?.filterName ||
            columns.find((column) => column.field === filter.filterName)?.headerName ||
            filter.filterName
        })}
        className={classes.tableFilterItem}
      />
    )
  })

  const mobileFilterMenu = (
    <Box className={classes.mobileFilterMenu}>
      <Box style={{display: 'flex', justifyContent: 'space-between'}}>
        <Button
          variant="text"
          color="primary"
          onClick={handleMobileMenuClick}
          data-test-id="mobile-button-add-filter"
          className={classes.filterListButton}
        >
          <FilterListIcon className={classes.filterListIcon} />
          {t('tableFilter.addFilter')}
        </Button>
        <CloseButton onClose={() => setShouldOpenMobileFilterMenu(false)} />
      </Box>
      {tableFilterItemList}
    </Box>
  )

  const formattedColumns = columns
    .filter((column) => column.filterable)
    .map(
      (column) =>
        ({
          filterField: column.field,
          filterName: column.filterName || column.headerName,
          filterType: column.filterType,
          filterDropdownOptions: column.filterDropdownOptions,
          filterDatePickerRangeOptions: column.filterDatePickerRangeOptions && {
            validFrom: {
              filterField: column?.filterDatePickerRangeOptions?.validFromField || ''
            },
            validTo: {
              filterField: column?.filterDatePickerRangeOptions?.validToField || ''
            }
          },
          filterShowSearch: column.filterShowSearch,
          filterMultiSelect: column.filterMultiSelect,
          noSelectionDropdownLabel: column.noSelectionDropdownLabel
        }) as TableFilterType
    )

  const filterList = extraFilters ? formattedColumns.concat(extraFilters) : formattedColumns

  const mappedFiltersInputs = filterListInputValues.map((value) => value.filterField)

  const filterSelectMenu = (
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
      <Button className={classes.resetFiltersButton} onClick={handleResetFilters}>
        {t('tableFilter.resetFilters')}
      </Button>
      {filterList
        .sort((a, b) =>
          a.filterName && b.filterName ? a.filterName.localeCompare(b.filterName) : 0
        )
        .map((filter, index) => (
          <MenuItem
            key={index}
            onClick={() => handleFilter(filter)}
            data-test-id={`filter-menu-item-${filter.filterField}`}
          >
            <Checkbox
              disableRipple
              color="primary"
              checked={mappedFiltersInputs.includes(filter.filterField)}
              icon={<span className={clsx(classes.checkboxIcon, classes.uncheckedBorder)} />}
              checkedIcon={
                <span className={clsx(classes.checkboxIcon, classes.checkboxCheckedIcon)} />
              }
              classes={{indeterminate: classes.checkboxIndeterminateIcon}}
            />
            <Typography style={{fontWeight: 500, color: '#00274D'}}>{filter.filterName}</Typography>
          </MenuItem>
        ))}
    </Menu>
  )

  useEffect(() => {
    !isMobile && setShouldOpenMobileFilterMenu(false)
  }, [isMobile])

  return (
    <Box
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: isMobile ? 'row-reverse' : 'row',
        gap: '10px'
      }}
    >
      <Button
        variant="text"
        color="primary"
        onClick={handleMenuClick}
        data-test-id="button-add-filter"
        className={classes.filterListButton}
      >
        <Badge badgeContent={filterListInputValues.length} color="primary" invisible={!isMobile}>
          <FilterListIcon className={classes.filterListIcon} />
        </Badge>
        {!isMobile && t('tableFilter.addFilter')}
      </Button>
      {filterSelectMenu}
      {!isMobile && tableFilterItemList}
      {shouldOpenMobileFilterMenu && mobileFilterMenu}
    </Box>
  )
}
