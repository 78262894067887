import {
  Toolbar as AbstractToolbar,
  ToolbarProps as AbstractToolbarProps,
  Theme,
  makeStyles
} from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

export type ToolbarColor = 'white' | 'grey' | 'transparent'

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    flexFlow: 'row wrap',
    paddingTop: 24,
    paddingBottom: 16
  },
  endLastItem: {
    '&>:last-child:not(:first-child)': {
      marginLeft: 'auto'
    }
  },
  white: {
    background: theme.palette.background.paper
  },
  grey: {
    // missing theme color here
    background: '#F7F7F7'
  },
  transparent: {
    background: 'transparent'
  }
}))

export enum HorizontalAlignment {
  End = 'flex-end',
  Center = 'center',
  Start = 'flex-start'
}

export interface ToolbarProps {
  className?: string
  color?: ToolbarColor
  notEndLast?: boolean
  horizontalAlignment?: HorizontalAlignment
  disableGutters?: boolean
}

export const Toolbar: React.FC<ToolbarProps & AbstractToolbarProps> = ({
  children,
  color = 'white',
  className: classNameProp,
  horizontalAlignment = HorizontalAlignment.Start,
  notEndLast = false,
  ...props
}) => {
  const classes: any = useStyle(props)
  return (
    <AbstractToolbar
      style={{
        display: 'flex',
        justifyContent: horizontalAlignment
      }}
      className={classNames(
        classes.root,
        classes[color],
        {
          [classes.endLastItem]: !notEndLast
        },
        classNameProp
      )}
      {...props}
    >
      {children}
    </AbstractToolbar>
  )
}
