import {alpha, makeStyles, Theme} from '@material-ui/core'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import i18n from 'i18next'
import moment, {Moment} from 'moment'
import React from 'react'
import {DayPickerRangeController, DayPickerSingleDateController} from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import {Dates, FocusedInput} from '../../../../common'

const useStyle = makeStyles((theme: Theme) => ({
  prevMonth: {
    position: 'absolute',
    left: '24px',
    top: '19px'
  },
  nextMonth: {
    position: 'absolute',
    right: '24px',
    top: '19px'
  },
  overrides: {
    '& .CalendarDay__default': {
      background: '#001e2a',
      color: alpha(theme.palette.common.white, 0.4),
      fontFamily: 'Roboto',
      border: '1px solid #33505b',
      height: '24px',
      '& :hover': {
        background: alpha(theme.palette.common.white, 0.4),
        border: '1px solid #33505b',
        color: '#fff'
      }
    },
    '& .CalendarDay__selected_span': {
      background: alpha(theme.palette.common.white, 0.2),
      color: '#fff'
    },
    '& .CalendarDay__selected': {
      color: '#fff',
      background: alpha(theme.palette.common.white, 0.4)
    },
    '& .CalendarDay__selected:hover, & .CalendarDay__selected_span:hover': {
      color: '#fff',
      border: '1px solid #33505b',
      background: alpha(theme.palette.common.white, 0.4)
    },
    '& .CalendarDay__hovered_span:hover, & .CalendarDay__hovered_span': {
      background: alpha(theme.palette.common.white, 0.2),
      color: '#fff'
    },
    '& .CalendarDay__blocked_out_of_range:hover, & .CalendarDay__blocked_out_of_range, & .CalendarDay__blocked_calendar':
      {
        background: alpha(theme.palette.common.white, 0.1),
        color: alpha(theme.palette.common.white, 0.1)
      },
    '& .CalendarMonth': {
      background: '#001e2a',
      color: '#fff'
    },
    '& .CalendarMonth_caption': {
      color: alpha(theme.palette.common.white, 0.96),
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontWeight: '500',
      paddingTop: '19px'
    },
    '& .CalendarMonthGrid, & .DayPicker': {
      background: '#001e2a'
    },
    '& .DayPicker__withBorder': {
      boxShadow: 'none'
    },
    '& .DayPicker_weekHeader': {
      color: alpha(theme.palette.common.white, 0.6),
      fontFamily: 'Roboto',
      top: '46px'
    }
  },
  overridesLight: {
    '& .CalendarDay__default': {
      background: theme.palette.common.white,
      color: theme.palette.text.primary,
      fontFamily: 'Roboto',
      border: `1px solid ${theme.palette.secondary.dark}`,
      height: '24px',
      '& :hover': {
        background: alpha(theme.palette.common.white, 0.4),
        border: `1px solid ${theme.palette.secondary.dark}`,
        color: theme.palette.secondary.dark
      }
    },
    '& .CalendarDay__selected_span': {
      background: theme.palette.secondary.dark,
      color: theme.palette.common.white
    },
    '& .CalendarDay__selected': {
      color: theme.palette.common.white,
      background: alpha(theme.palette.secondary.dark, 0.8)
    },
    '& .CalendarDay__selected:hover, & .CalendarDay__selected_span:hover': {
      color: theme.palette.common.white,
      border: `1px solid ${theme.palette.secondary.dark}`,
      background: theme.palette.secondary.dark
    },
    '& .CalendarDay__hovered_span:hover, & .CalendarDay__hovered_span': {
      background: theme.palette.secondary.dark,
      color: theme.palette.common.white
    },
    '& .CalendarDay__blocked_out_of_range:hover, & .CalendarDay__blocked_out_of_range, & .CalendarDay__blocked_calendar':
      {
        background: theme.palette.action.disabledBackground,
        color: alpha(theme.palette.text.primary, 0.4)
      },
    '& .CalendarMonth': {
      background: theme.palette.common.white,
      color: theme.palette.text.primary
    },
    '& .CalendarMonth_caption': {
      color: theme.palette.text.primary,
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontWeight: '500',
      paddingTop: '19px'
    },
    '& .CalendarMonthGrid, & .DayPicker': {
      background: theme.palette.common.white
    },
    '& .DayPicker__withBorder': {
      boxShadow: 'none'
    },
    '& .DayPicker_weekHeader': {
      color: theme.palette.secondary.dark,
      fontFamily: 'Roboto',
      top: '46px'
    }
  }
}))

interface Props {
  selectedStartDate: Moment | null
  selectedEndDate: Moment | null
  focusedInput: FocusedInput | null
  onFocusChange: () => void
  dateControllerProps: Partial<DayPickerSingleDateController & DayPickerRangeController>
  onDatesChange?: (dates: Dates) => void
  onSingleDateChange?: (date: Moment | null) => void
  singleSelection?: boolean
  onLight?: boolean
}

const DatePickerCalendar: React.FC<Props> = ({
  selectedStartDate,
  selectedEndDate,
  focusedInput,
  onFocusChange,
  dateControllerProps,
  onDatesChange,
  onSingleDateChange,
  singleSelection,
  onLight = false
}) => {
  const classes = useStyle()
  const {language} = i18n

  if (!onSingleDateChange || !onDatesChange) return null
  if (singleSelection) {
    return (
      <div className={onLight ? classes.overridesLight : classes.overrides}>
        <DayPickerSingleDateController
          {...dateControllerProps}
          focused={true}
          navPrev={
            <ChevronLeft color={onLight ? 'secondary' : 'primary'} className={classes.prevMonth} />
          }
          navNext={
            <ChevronRight color={onLight ? 'secondary' : 'primary'} className={classes.nextMonth} />
          }
          numberOfMonths={1}
          daySize={24}
          hideKeyboardShortcutsPanel
          date={selectedStartDate}
          onFocusChange={onFocusChange}
          onDateChange={onSingleDateChange}
          initialVisibleMonth={() =>
            selectedStartDate?.locale(language) || moment().locale(language)
          }
        />
      </div>
    )
  }
  return (
    <div className={onLight ? classes.overridesLight : classes.overrides}>
      <DayPickerRangeController
        navPrev={
          <ChevronLeft htmlColor={onLight ? '#33505b' : '#fff'} className={classes.prevMonth} />
        }
        navNext={
          <ChevronRight htmlColor={onLight ? '#33505b' : '#fff'} className={classes.nextMonth} />
        }
        numberOfMonths={1}
        daySize={24}
        minimumNights={0}
        hideKeyboardShortcutsPanel
        startDate={selectedStartDate}
        endDate={selectedEndDate}
        focusedInput={focusedInput}
        onFocusChange={onFocusChange}
        onDatesChange={onDatesChange}
        initialVisibleMonth={() => selectedStartDate?.locale(language) || moment().locale(language)}
        {...dateControllerProps}
      />
    </div>
  )
}

export {DatePickerCalendar}
