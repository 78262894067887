import {alpha, makeStyles} from '@material-ui/core'
import DateRange from '@material-ui/icons/DateRange'
import classnames from 'classnames'
import React, {RefObject} from 'react'
import {useTranslation} from 'react-i18next'

import {FocusedInput} from '../../../../common'
import TitleButton from '../../Title/Components/TitleButton'
import Typography from '../../Typography'

const useStyle = makeStyles((theme) => ({
  button: {
    width: '100%',
    justifyContent: 'flex-start'
  },
  textContainer: {
    textAlign: 'left',
    color: alpha(theme.palette.secondary.dark, 0.96),
    cursor: 'pointer',
    flexShrink: 1,
    position: 'relative',
    width: '100%',
    whiteSpace: 'nowrap'
  },
  textIcon: {
    background: 'transparent',
    color: theme.palette.secondary.dark,
    marginLeft: theme.spacing(2),
    marginTop: -theme.spacing(0.5)
  },
  focused: {
    color: 'rgba(255, 255, 255, 0.6) !important'
  },
  textField: {
    paddingTop: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 'auto'
  }
}))

interface Props {
  focus: boolean
  setFocus: (focus: boolean) => void
  setFocusedInput: (focusedInput: FocusedInput) => void
  text: string
  label?: React.ReactNode
  textClasses: string | undefined
  buttonRef: RefObject<HTMLButtonElement>
}

const DatePickerTextField: React.FC<Props> = ({
  focus,
  setFocus,
  setFocusedInput,
  label,
  text,
  textClasses,
  buttonRef,
  ...otherProps
}) => {
  const classes = useStyle()
  const {t} = useTranslation()
  return (
    <TitleButton
      className={classnames(classes.button, textClasses)}
      buttonRef={buttonRef}
      data-test-id="date-picker-toggle-button"
      onClick={() => {
        setFocus(!focus)
        setFocusedInput(FocusedInput.StartDate)
      }}
      {...otherProps}
    >
      <div className={classes.textContainer}>
        <Typography variant="caption" customColor="textPrimarySoft">
          {label ?? t('datePicker.label')}
        </Typography>
        <div className={classes.textField}>
          <Typography variant="h4" color="textPrimary" noWrap>
            {text}
          </Typography>
          <DateRange className={classes.textIcon} fontSize="medium" />
        </div>
      </div>
    </TitleButton>
  )
}

export {DatePickerTextField}
