import {alpha, makeStyles} from '@material-ui/core'
import classNames from 'classnames'
import {Moment} from 'moment'
import React, {SyntheticEvent} from 'react'
import {useTranslation} from 'react-i18next'

import {Dates, QuickSelectionItem} from '../../../../common'
import {rangeDate} from '../../../Tools'
import Typography from '../../Typography'

import {sameMomentDate} from './DatePickerUtil'

const useStyle = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    backgroundColor: 'transparent',
    width: 192
  },
  title: {
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`
  },
  selectionItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(231, 231, 231, .1)'
    },
    '&:active': {
      backgroundColor: 'rgba(231, 231, 231, .2)'
    }
  },
  selectionItemContainerLight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.light, 0.4)
    },
    '&:active': {
      backgroundColor: alpha(theme.palette.secondary.light, 0.5)
    }
  },
  active: {
    backgroundColor: 'rgba(231, 231, 231, .2)'
  },
  activeLight: {
    backgroundColor: alpha(theme.palette.secondary.light, 0.5)
  },
  selectionItemTitle: {},
  selectionItemValue: {}
}))

interface Props {
  items: QuickSelectionItem[]
  handleItemClick: (dates: Dates) => void
  selectedStartDate?: Moment
  selectedEndDate?: Moment
  onLight?: boolean
}

const DatePickerQuickSelection: React.FC<Props> = ({
  items,
  handleItemClick,
  selectedStartDate,
  selectedEndDate,
  onLight = false,
  ...otherProps
}) => {
  const classes = useStyle()
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const onSelectionItemClick = (event: SyntheticEvent, item: QuickSelectionItem) => {
    event.preventDefault()
    handleItemClick({startDate: item.dates[0], endDate: item.dates[1]})
  }
  return (
    <div className={classes.root} {...otherProps}>
      <div className={classes.title}>
        <Typography
          customVariant="captionBold"
          customColor={onLight ? 'textPrimarySoft' : 'textSecondarySoft'}
        >
          {t('datePicker.quickSelection')}
        </Typography>
      </div>
      {items.map((item: QuickSelectionItem, i: number) => (
        <div
          key={item.title}
          role="button"
          tabIndex={i - 1}
          aria-label={`Date Picker ${item.title} button`}
          data-test-id={`date-picker-${item.key ?? item.type}-button`}
          className={classNames(
            onLight ? classes.selectionItemContainerLight : classes.selectionItemContainer,
            {
              [onLight ? classes.activeLight : classes.active]:
                selectedStartDate &&
                selectedEndDate &&
                selectedStartDate.isSame(item.dates[0], 'day') &&
                selectedEndDate.isSame(item.dates[1], 'day')
            }
          )}
          onClick={(event) => onSelectionItemClick(event, item)}
        >
          <div className={classes.selectionItemTitle}>
            <Typography variant="body2" color={onLight ? 'textPrimary' : 'textSecondary'}>
              {item.title}
            </Typography>
          </div>
          <div className={classes.selectionItemValue}>
            <Typography variant="caption" color={onLight ? 'textPrimary' : 'textSecondary'}>
              {sameMomentDate(item.dates[0], item.dates[1])
                ? item.dates[0].locale(language).format('L')
                : item.dates[0] &&
                  item.dates[1] &&
                  rangeDate([item.dates[0], item.dates[1]], language, 'L')}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  )
}

export {DatePickerQuickSelection}
