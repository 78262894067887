import {Button, Box, Tooltip, makeStyles, Theme} from '@material-ui/core'
import DateRangeIcon from '@material-ui/icons/DateRange'
import React, {Ref} from 'react'

import Typography from '../../Typography'

interface Props {
  label: string
  onClick?: () => void
  buttonRef?: Ref<HTMLButtonElement>
  showTooltip?: boolean
  tooltipLabel?: string
  labelClass?: string
  iconClass?: string
  disabled?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    border: `${theme.spacing(0.125)}px solid ${theme.palette.primary.main}`
  }
}))

export const DatePickerButton: React.FC<Props> = ({
  buttonRef,
  label,
  onClick,
  showTooltip: showTooltip = false,
  tooltipLabel,
  labelClass,
  iconClass,
  disabled: disabled = false
}) => {
  const classes = useStyles()
  return (
    <Tooltip
      open={showTooltip}
      title={<span data-test-id="date-picker-button-tooltip">{tooltipLabel ?? ''}</span>}
      placement="right"
    >
      <span>
        <Button
          variant="text"
          ref={buttonRef}
          id="date-picker-toggle-button"
          data-test-id="date-picker-toggle-button"
          onClick={onClick ? () => onClick() : undefined}
          disabled={disabled}
          className={showTooltip ? classes.button : ''}
        >
          <Box display="flex" alignItems="center">
            <Typography customVariant="formLabel" color="primary" component="span">
              <Box className={labelClass}>{label}</Box>
            </Typography>
            <Box mx={0.25} />
            <Box className={iconClass}>
              <DateRangeIcon fontSize="inherit" color="primary" />
            </Box>
          </Box>
        </Button>
      </span>
    </Tooltip>
  )
}
