import {
  darken,
  Divider as AbstractDivider,
  DividerProps as AbstractDividerProps,
  makeStyles
} from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

import {HCThemeType} from '../HCTheme.types'

export type DividerColor = 'strong' | 'light' | 'soft' | 'dark' | 'onBlue' | 'primary' | 'onLight'

export interface DividerProps {
  color?: DividerColor
}

const useStyle = makeStyles((theme: HCThemeType) => ({
  strong: {
    backgroundColor: theme.palette.dividerStrong
  },
  light: {
    backgroundColor: theme.palette.text.primarySoft
  },
  soft: {
    backgroundColor: theme.palette.divider
  },
  dark: {
    backgroundColor: theme.palette.dividerDark
  },
  onBlue: {
    backgroundColor: theme.palette.dividerOnBlue
  },
  onBlueDisabled: {
    backgroundColor: darken(theme.palette.dividerOnBlue ?? 'rgba(255, 255, 255, 0.5)', 0.5)
  },
  primary: {
    backgroundColor: theme.palette.secondary.dark
  },
  onLight: {
    backgroundColor: '#e7e7e7'
  },
  onLightDisabled: {
    backgroundColor: '#b7b7b7'
  }
}))

const Divider: React.FC<DividerProps & AbstractDividerProps> = ({
  color = 'strong',
  className: classNameProp,
  children,
  ...props
}) => {
  const classes = useStyle()
  const className = classNames(classes[color], classNameProp)

  return (
    <AbstractDivider className={className} {...props}>
      {children}
    </AbstractDivider>
  )
}

export default Divider
