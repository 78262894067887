import {Box, Link} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import classNames from 'classnames'
import {isEmpty} from 'lodash'
import React from 'react'
import {NavLink as NavLinkComponent} from 'react-router-dom'

import {useNavLinkStyles} from '../styles'
import {NavItem} from '../types'

import {MenuLinksBox} from './MenuLinksBox'
import {NavMenuLinks} from './NavMenuLinks'

interface NavMenuProps {
  handleSelect: (item?: NavItem | undefined) => void
  isMobile: boolean
  items?: NavItem[]
  onNavItemHover?: (route: string) => void
  keepPointerEvents?: boolean
  navItemClassNames?: string
}
export const NavMenu: React.FC<NavMenuProps> = ({
  items,
  handleSelect,
  isMobile,
  onNavItemHover,
  keepPointerEvents,
  navItemClassNames
}) => {
  const {activeLink, disabledLink, link, linkWrapper, linkWrapperCentered} = useNavLinkStyles()
  const [activeNavItemClassName, navItemClassName] = (navItemClassNames ?? '').split(' ')
  const [isNavOpen, setIsNavOpen] = React.useState<string | undefined>(undefined)
  const ref = React.useRef<HTMLDivElement>(null)
  const handleToggleNav = (key?: string) => {
    if (key === isNavOpen) {
      setIsNavOpen(undefined)
    }
    setIsNavOpen(key)
  }
  const onSelect = (item?: NavItem) => {
    handleSelect(item)
    setIsNavOpen(undefined)
  }
  React.useEffect(() => {
    // close dropdown on click outside of the box on the page
    const handler = (event) => {
      if (isNavOpen && ref.current && !ref.current.contains(event.target)) {
        setIsNavOpen(undefined)
      }
    }
    document.addEventListener('mousedown', handler)
    document.addEventListener('touchstart', handler)
    return () => {
      // cleanup the event listener
      document.removeEventListener('mousedown', handler)
      document.removeEventListener('touchstart', handler)
    }
  }, [isNavOpen])

  return (
    <>
      {items
        ? items.map((item) => {
            const {label, url, dataTestId, isDisabled = false, subItems} = item
            return (
              <div key={label}>
                {subItems && subItems.length > 0 && isNavOpen === label ? (
                  <div ref={ref}>
                    <MenuLinksBox
                      sx={{
                        position: 'absolute',
                        left: 'auto',
                        right: 'auto',
                        top: '125%',
                        boxShadow:
                          '0px 0px 1px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.12)',
                        paddingLeft: 'auto',
                        paddingRight: 'auto'
                      }}
                    >
                      <NavMenuLinks
                        navItems={subItems}
                        data-test-id={`${dataTestId}-list`}
                        onSelect={onSelect}
                        isMobile={isMobile}
                        keepPointerEvents={keepPointerEvents}
                      />
                    </MenuLinksBox>
                  </div>
                ) : null}
                <Box
                  className={classNames(linkWrapper, {[linkWrapperCentered]: !isEmpty(subItems)})}
                >
                  <Link
                    underline="hover"
                    component={NavLinkComponent}
                    onClick={() => onSelect(item)}
                    onMouseEnter={() => onNavItemHover?.(item.url)}
                    to={url}
                    className={classNames(link, {[disabledLink]: isDisabled}, navItemClassName)}
                    activeClassName={url === '#' ? link : activeNavItemClassName || activeLink}
                    data-test-id={dataTestId}
                  >
                    {label}
                  </Link>
                  {subItems && subItems.length > 0 ? (
                    <KeyboardArrowDownIcon
                      onClick={() => handleToggleNav(label)}
                      style={{marginLeft: 8, cursor: 'pointer'}}
                      data-test-id={`${dataTestId}-dropdown-arrow`}
                      className={navItemClassName}
                    />
                  ) : null}
                </Box>
              </div>
            )
          })
        : null}
    </>
  )
}
