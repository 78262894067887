import {alpha, Button, Grid, makeStyles, Theme} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'

const useStyle = makeStyles((theme: Theme) => ({
  dateButton: {
    color: 'rgba(41, 170, 255, 0.96)',
    border: 'none',
    margin: theme.spacing(2),
    padding: 0,
    '&:hover': {
      boxShadow: 'none',
      background: 'transparent',
      textDecoration: 'underline'
    },
    '&:disabled': {
      border: 'none',
      boxShadow: 'none',
      color: alpha(theme.palette.common.white, 0.4)
    }
  },
  dateButtonLight: {
    color: 'rgba(41, 170, 255, 0.96)',
    border: 'none',
    margin: theme.spacing(2),
    padding: 0,
    '&:hover': {
      boxShadow: 'none',
      background: 'transparent',
      textDecoration: 'underline'
    },
    '&:disabled': {
      border: 'none',
      boxShadow: 'none',
      color: theme.palette.action.disabled
    }
  }
}))

interface Props {
  clearDisabled: boolean
  applyDisabled: boolean
  handleClear: () => void
  handleApply: () => void
  hideClearButton?: boolean
  hideApplyButton?: boolean
  onLight: boolean
}

const DatePickerActionButtons: React.FC<Props> = ({
  clearDisabled,
  applyDisabled,
  handleClear,
  handleApply,
  hideClearButton = false,
  hideApplyButton = false,
  onLight = false
}) => {
  const classes = useStyle()
  const {t} = useTranslation()
  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="baseline">
      <Grid item>
        {!hideClearButton && (
          <Button
            id="date-picker-clear-button"
            className={onLight ? classes.dateButtonLight : classes.dateButton}
            disabled={clearDisabled}
            onClick={handleClear}
          >
            {t('datePicker.clear')}
          </Button>
        )}
      </Grid>
      {!hideApplyButton ? (
        <Grid item>
          <Button
            id="date-picker-apply-button"
            className={onLight ? classes.dateButtonLight : classes.dateButton}
            disabled={applyDisabled}
            onClick={handleApply}
          >
            {t('datePicker.apply')}
          </Button>
        </Grid>
      ) : null}
    </Grid>
  )
}

export {DatePickerActionButtons}
