/* eslint-disable max-classes-per-file */

import React, {ReactNode} from 'react'
import {BrowserRouter, Route, RouteProps, RouteComponentProps} from 'react-router-dom'

import {History} from './History'

interface Routes {
  path: string
  routes: any
}

export enum PermissionTypes {
  VIEW_ORDERS_DELIVERIES = 'VIEW_ORDERS_DELIVERIES',
  CHANGE_ORDERS = 'CHANGE_ORDERS',
  CREATE_ORDERS = 'CREATE_ORDERS',
  VIEW_FINANCE = 'VIEW_FINANCE',
  VIEW_INVOICES = 'VIEW_INVOICES',
  CHANGE_INVOICES = 'CHANGE_INVOICES',
  VIEW_QOUTES = 'VIEW_QOUTES',
  CHANGE_QUOTES = 'CHANGE_QUOTES',
  VIEW_TESTS = 'VIEW_TESTS',
  CHANGE_TESTS = 'CHANGE_TESTS',
  VIEW_ALL_DATA = 'VIEW_ALL_DATA',
  CHANGE_ALL_DATA = 'CHANGE_ALL_DATA',
  VIEW_USERS = 'VIEW_USERS',
  INVITE_USERS = 'INVITE_USERS',
  DELETE_USERS = 'DELETE_USERS',
  CHANGE_USERS = 'CHANGE_USERS',
  GRANT_USER_MANAGEMENT_RIGHTS = 'GRANT_USER_MANAGEMENT_RIGHTS',
  GRANT_SUPERADMIN_RIGHTS = 'GRANT_SUPERADMIN_RIGHTS',
  VIEW_CUSTOMERS = 'VIEW_CUSTOMERS',
  VIEW_PLANTS = 'VIEW_PLANTS',
  VIEW_MATERIALCERTIFICATES = 'VIEW_MATERIALCERTIFICATES',
  VIEW_MATERIALTESTS = 'VIEW_MATERIALTESTS',
  ACCESS_PRODUCT_HUB = 'ACCESS_PRODUCT_HUB',
  VIEW_CREDIT = 'VIEW_CREDIT',
  CHANGE_MATERIALCERTIFICATES_SNIPPETS = 'CHANGE_MATERIALCERTIFICATES_SNIPPETS',
  CHANGE_FINANCE = 'CHANGE_FINANCE',
  VIEW_PAYERS = 'VIEW_PAYERS'
}

const routes: Routes = {
  path: '/',
  routes: {}
}

const isPlaceholder = (pathSegment: string) => pathSegment.indexOf(':') === 0

const getRouteByPathSegments = (paths: string[]) =>
  Array.isArray(paths) &&
  paths.reduce((prev, current) => {
    if (!current) {
      return prev
    }
    if (prev && prev.routes) {
      if (prev.routes[current]) {
        return prev.routes[current]
      }
      // search for placeholder like "/:productId"
      const found = Object.keys(prev.routes).find(isPlaceholder)
      if (found) {
        return prev.routes[found]
      }
    }
    return null
  }, routes)

const removeRouteByPath = (path: string): void => {
  const paths = path.replace(/^\//, '').split('/')
  const routeName = paths.pop()
  if (routeName) {
    const parentRoute = getRouteByPathSegments(paths)
    if (parentRoute && parentRoute.routes[routeName]) {
      delete parentRoute.routes[routeName]
    }
  }
}

const getRouteByPath = (path: string) =>
  path ? getRouteByPathSegments(path.replace(/^\//, '').split('/')) : null

const addRoute = ({path, component, ...otherProps}: {path: string; component: React.ReactNode}) => {
  if (path.indexOf('/') !== 0) {
    throw new TypeError(`Path has to start with "/"! Please check your Route for "${path}".`)
  }
  if (!component) {
    throw new TypeError(`A route must have a component! Please add a component for "${path}".`)
  }

  // remove trailing slash and split into PathSegments
  const [, ...paths] = path.replace(/\/$/, '').split('/')
  // FIXME remove type assertion
  const name = paths.pop() as string
  const route = getRouteByPathSegments(paths)

  if (!route) {
    throw new ReferenceError(
      `Parent route for "/${name}" not found! Please check your hierarchy at ${path}.`
    )
  }

  const placeholderRoute = Object.keys(route.routes).find(isPlaceholder)
  if (isPlaceholder(name) && placeholderRoute) {
    throw new Error(
      `There is already a placeholder route "${placeholderRoute}" in ${route.path}! You can only register one placeholder route inside another route.`
    )
  }

  if (route.routes[name]) {
    if (process.env.NODE_ENV === 'development') {
      console.warn(`Route for "/${name}" in "${route.path}" already exists! Overwriting...`)
    }
  }

  route.routes[name] = {
    path,
    component,
    routes: {},
    ...otherProps
  }
  return route.routes[name]
}

const withRouter =
  (Router = BrowserRouter, routerProps = {}) =>
  (WrappedComponent: React.ComponentType<any>) =>
    class extends React.Component {
      render() {
        return (
          <Router {...routerProps}>
            <Route
              path="/"
              render={(props: any) => <WrappedComponent {...this.props} {...props} />}
            />
          </Router>
        )
      }
    }

interface WithRouteProps {
  routeType?: string
  path: string | string[]
  routeProps?: RouteProps
  order?: number
  label?: string
  labelKey?: string
  hideInMainNav?: boolean
  hideInNav?: boolean
  permissions?: PermissionTypes[]
  featureToggleName?: string
  excludeFeatureToggleName?: string
}

const withRoute =
  ({routeType, path, routeProps, ...otherProps}: WithRouteProps) =>
  (WrappedComponent: React.ComponentType<any>): React.ComponentType<RouteProps> => {
    const component = class extends React.Component<RouteProps> {
      render() {
        switch (routeType) {
          case 'children':
            return (
              <Route path={path} {...routeProps}>
                <WrappedComponent {...this.props} />
              </Route>
            )

          case 'component':
            return <Route path={path} {...routeProps} component={WrappedComponent} />

          case 'render':
          // fall through

          default:
            return (
              <Route
                path={path}
                {...routeProps}
                render={(props: any) => <WrappedComponent {...this.props} {...props} />}
              />
            )
        }
      }
    }
    if (Array.isArray(path)) {
      path.forEach((p) => {
        addRoute({
          path: p,
          component: component as unknown as React.ReactNode,
          ...otherProps
        })
      })
    } else {
      addRoute({
        path,
        component: component as unknown as React.ReactNode,
        ...otherProps
      })
    }

    return component
  }

export {History, withRouter, addRoute, getRouteByPath, removeRouteByPath, withRoute}
