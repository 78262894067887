import {TextField, makeStyles, TextFieldProps} from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {HCThemeType as HcTheme} from '../HCTheme.types'

import Typography from './Typography'

const useStyles = makeStyles((theme: HcTheme) => ({
  root: {
    width: '100%'
  },
  error: {
    borderColor: `${theme.palette.error.main}!important`
  },
  warning: {
    borderColor: `${theme.palette.warning.main}!important`
  },
  warningText: {
    color: `${theme.palette.warning.main}!important`
  },
  inputContainer: {
    width: '100%',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    borderRadius: 4,
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[100]}`,
    '&:hover': {
      '& $textFontFace': {
        textDecoration: 'underline'
      },
      background: theme.palette.grey[100],
      borderColor: theme.palette.grey[200]
    },
    '& .MuiInputLabel-formControl': {
      color: theme.palette.text.primarySoft
    },
    '& .MuiInput-underline, & .MuiInput-underline:hover': {
      '&::after,&::before': {
        border: 'none'
      }
    }
  },
  input: {
    width: '100%'
  }
}))

interface Props {
  error?: boolean
  warning?: boolean
  required?: boolean
  requiredLabel?: string
  warningLabel?: string
}

export const BorderedTextField: React.FC<Props & TextFieldProps> = ({
  error,
  warning,
  required,
  requiredLabel,
  warningLabel,
  ...TextFieldProps
}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div
        className={classNames(
          classes.inputContainer,
          error ? classes.error : null,
          warning ? classes.warning : null
        )}
      >
        <TextField className={classes.input} {...TextFieldProps} />
      </div>
      {required ? (
        <Typography variant="caption" color="secondary" component="span">
          {requiredLabel ? requiredLabel : t('required')}
        </Typography>
      ) : null}
      {warning ? (
        <Typography variant="caption" component="span" className={classes.warningText}>
          {warningLabel}
        </Typography>
      ) : null}
    </div>
  )
}
